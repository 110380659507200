.spinner {
  display: inline-block;
  width: 120px;
  height: 120px;
  position: fixed;
  top: 40%;
  left: 40%;
}

.spinner:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border: 15px solid transparent;
  border-top-color: red;
  border-right-color: red;
  border-radius: 50%;
  transform: rotate(-75deg);
  animation: rotate 1s infinite ease-out;
}

.spinner:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border: 15px solid transparent;
  border-bottom-color: #1f0d33;
  border-left-color: #1f0d33;
  border-radius: 50%;
  transform: rotate(-75deg);
  animation: rotate 2s infinite ease-out;
}

.image {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(315deg);
  }
}